import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Form from 'react-bootstrap/Form';

function AgreementLab(props) {
    const [agreementChecked, setAgreementChecked] = useState(false);

    var agreement;

    const toggleAgreement = () => {
        if (agreementChecked) {
            setAgreementChecked(false);
        } else {
            setAgreementChecked(true);
            if (props.onCompletion)
                props.onCompletion();
        }
    };


    agreement = (
        <React.Fragment>
            <Form.Group id="formGridCheckbox">
                <hr />
                <Form.Label className="text-center" style={{ width: "100%" }}>Nota sulla mancata persistenza delle macchine virtuali</Form.Label>
                <TextareaAutosize
                    readOnly
                    cols={50}
                    //maxRows={10}
                    rows={25}
                    wrap="true"
                    value="
                        Le macchine del presente laboratorio virtuale non sono persistenti e non sono sottoposte a procedure di backup.
                        Questo significa che eventuali dati o documenti memorizzati all’interno della macchina virtuale andranno persi, allo spegnimento della stessa. Utilizzare i servizi Google G Suite (es Google Drive) per salvare e ricaricare i propri elaborati nelle macchine virtuali tra una sessione e quella successiva.
                        Le suddette macchine sono soggette all'attività di manutenzione nell'orario 00:00/07:00: il loro utilizzo in queste ore può presentare rallentamenti e/o riavvii delle stesse.
                        [La presente nota è aggiornata al 07/06/2023]
                    "
                />

                <Form.Check
                    required
                    onChange={toggleAgreement}
                    type="checkbox"
                    id="agreement"
                    label={"Confermo di aver preso visione della nota sulla mancata persistenza delle macchine virtuali"}
                />
                <br />
                <hr />



            </Form.Group>
        </React.Fragment>
    );

    return agreement;

}


export default AgreementLab;
