import React, { useState, useEffect, useMemo } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link45deg } from "react-bootstrap-icons";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';


function SubbedLabPage(props) {
    const [labData, setLabData] = useState([
        {
            email: "undefined",
            idLab: "undefined",
            labName: "undefined",
            linkVdi: "undefined"
        }
    ]);
    const [isLoading, setIsLoading] = useState(1)
    const [numLabSubbed, setNumLabSubbed] = useState(0);

    //should be memoized or stable
    const labColumns = useMemo(
        () => [
            {
                accessorKey: 'email',
                header: 'E-Mail',
                size: 150,
            },
            {
                accessorKey: 'idLab',
                header: 'Identificativo Laboratorio',
                size: 150,
            },
            {
                accessorKey: 'labName', //normal accessorKey
                header: 'Nome Laboratorio',
                size: 200,
            },
            {
                accessorKey: 'linkVdi',
                header: 'Accesso Laboratorio',
                size: 200,
            },

        ],
        [],
    );


    useEffect(() => {


        var auxLab = 0;
        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
        };

        setTimeout(async () => {
            setIsLoading(1);
            var myUrl = (process.env.REACT_APP_URL_GET_PERSONAL_LAB);

            await fetch(myUrl, {
                credentials: 'include',
                method: "GET"
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    var linkVdi = "";
                    data.forEach(data => {
                        auxLab++;
                        linkVdi = "";

                        switch (data.status) {
                            case "complete":

                                linkVdi = (
                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                        {(popupState) => (
                                            <div>
                                                <Button variant="contained" {...bindTrigger(popupState)}>
                                                    Link per accedere alle VM
                                                </Button>
                                                <Popover
                                                    {...bindPopover(popupState)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Typography sx={{ p: 2 }}>Indirizzo per accedere alle VM del laboratorio.
                                                        Per accedere alle VM: <br />
                                                        a) utilizzare un browser HTML5 (es. Chrome, Edge), inserendo questo URL nella barra degli indirizzi <br />
                                                        b) utilizzare il client VMWare Horizon, specificando questo URL come "Connection Server". <br />
                                                        Indirizzo:<br />
                                                        <strong>{data.baseImage.destination.url}</strong>
                                                        {"     "}
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href=
                                                            {data.baseImage.destination.url}
                                                        ><Link45deg /></a>
                                                        <br /></Typography>
                                                </Popover>
                                            </div>
                                        )}
                                    </PopupState>
                                );

                                break;
                            default:
                                linkVdi = "Temporaneamente non disponibile"
                        }


                        data.linkVdi = linkVdi;

                        data.createdAt = convertDate(data.createdAt);

                    });

                    setLabData(data);
                    setNumLabSubbed(auxLab);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
            setIsLoading(0);
        });

    }, [props]);



    const table =
        useMaterialReactTable({
            columns: labColumns,
            data: labData || [],
        });


    const isLoadingPage = () => {
        var retLoadingPage = null;

        if (isLoading) {
            retLoadingPage = (
                <React.Fragment>
                    <ClipLoader
                        size={300}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </React.Fragment>
            )
        }

        return retLoadingPage;
    }







    const resultTable = () => {
        var ret = null;

        if (!isLoading && numLabSubbed > 0) {
            if (labData === "undefined" || labData === null || labData.length === 0) {
                ret = (<b>Non ci sono laboratori virtuali a cui sei iscritto</b>);

            } else {




                ret = (

                    <MaterialReactTable table={table} />

                );
            }
        }

        return ret;
    }

    const pubLabPage = () => {
        var retPub = null;

        retPub = (
            <React.Fragment>
                <h2 className="title-page">Laboratori Virtuali</h2>
                <p>Il suo account risulta iscritto a {numLabSubbed} Laboratori Virtuali</p>

                {isLoadingPage()}
                {resultTable()}

            </React.Fragment>
        );

        return retPub;
    }


    var ret = (
        <React.Fragment>
            {pubLabPage()}
        </React.Fragment>
    );

    if (labData) {
        return ret;
    } else {
        return null;
    }


}

export default SubbedLabPage;
