import React  from 'react';
import Can from "../Can";
import StanzaPage from "./StanzaPage";

function Stanza(props) {
    var myHome;

    if(!props.apiAuthGroups) {
        myHome = (
            null              
        )
    } else {
        myHome = (
            <Can
                role={props.apiAuthGroups}
                perform="labRequest-page:visit"
                yes={() => (
                    <StanzaPage 
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}                    
                    />
                )}
                no={() => (null)}            
            />
        )
    }


    return myHome;
  }
  
  export default Stanza;