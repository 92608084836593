import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';



function SelectSize(props) {
    const [currValue, setCurrValue] = useState(0);
    const [currHw, setCurrHw] = useState();
    const [isCustomizable, setIsCustomizable] = useState(false);

    var ret;
    var size = "";

    const rangeHandler = (e) => {
        switch (e.target.value) {
            case "0":
                size = "small";
                setCurrHw("1 CPU, 2GB RAM")
                break;
            case "1":
                size = "medium";
                setCurrHw("2 CPU, 4GB RAM")
                break;
            case "2":
                size = "large";
                setCurrHw("4 CPU, 8GB RAM")
                break;
            case "3":
                size = "xlarge";
                setCurrHw("8 CPU, 8GB RAM")
                break;
            default:
                size = "large";
                setCurrHw("4 CPU, 8GB RAM")
                break;
        }
        setCurrValue(e.target.value);

        var tempBaseImage = props.baseImage;
        tempBaseImage.imageSize = size;
        props.setBaseImage(tempBaseImage);
    }

    useEffect(() => {
        if (props.baseImage) {

            switch (props.baseImage.imageSize) {
                case "small":
                    setCurrValue(0);
                    setCurrHw("1 CPU, 2GB RAM")
                    break;
                case "medium":
                    setCurrValue(1);
                    setCurrHw("2 CPU, 4GB RAM")
                    break;
                case "large":
                    setCurrValue(2);
                    setCurrHw("4 CPU, 8GB RAM")
                    break;
                case "xlarge":
                    setCurrValue(3);
                    setCurrHw("8 CPU, 8GB RAM")
                    break;
                default:
                    setCurrValue(2);
                    setCurrHw("4 CPU, 8GB RAM")
            }

            if (props.baseImage.imageSizeCustomizable === "0")
                setIsCustomizable(false);
            else
                setIsCustomizable(true);
        }

    }, [props.baseImage]);

    if (props.baseImage.imageName) {
        var maxImageSize = 2;
        // xlarge solo per destination avs
        if (props.baseImage.destination.id === "2") {
            maxImageSize = 3;
        }

        ret = (
            <React.Fragment>
                <Form.Group controlId="formLabRequestSize" style={{ width: "85%" }}>
                    <Form.Label>Dimensionamento macchine</Form.Label>
                    <Row>
                        <Col>
                            <RangeSlider
                                disabled={!isCustomizable}
                                min={0}
                                max={maxImageSize}
                                value={currValue}
                                onChange={changeEvent => rangeHandler(changeEvent)}
                                tooltip="off"
                            />
                        </Col><Col>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${currHw}`}
                                readOnly
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <br />
            </React.Fragment>


        );
    } else {
        ret = null;
    }

    return ret;
}

export default SelectSize;