import React from 'react';
import Can from "../Can";
import PubLabPage from "./PubLabPage";

function PubLab(props) {
    var myHome;

    if (!props.apiAuthGroups) {
        myHome = (
            null
        )
    } else {
        myHome = (
            <Can
                role={props.apiAuthGroups}
                perform="labRequest-page:visit"
                yes={() => (
                    <PubLabPage
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        isAdmin={false}
                    />
                )}
                no={() => (null)}
            />
        )
    }


    return myHome;
}

export default PubLab;