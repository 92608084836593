import rules from "../rbac-rules";

const check = (rules, role, action, data) => {

    var isAllowed = false;

    role.forEach(myRole => {
        const permissions = rules[myRole];
        if(permissions) {
            const staticPermissions = permissions.static;

            if (staticPermissions && staticPermissions.includes(action)) {
                // static rule not provided for action
                isAllowed = true;
              }
        }
    })

    return isAllowed;

};

const Can = props =>
  check(rules, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();


export default Can;