const rules = {
  LAB_CREATOR: {
    static: [
      "teacherArea:visible",
      "labRequest-page:visit",
      "labRequest-page:confirm",
      "labGuide-page:visit",
      "labSubbed-page:visit",
    ]
  },
  LAB_DEMO: {
    static: [
      "teacherArea:visible",
      "labRequest-page:visit",
      "labGuide-page:visit",
    ]
  },
  LAB_EXAM_CREATOR: {
    static: [
      "adminLabArea:visible",
      "labExamRequest-page:visit",
      "labExamRequest-page:confirm",
      "labGuide-page:visit",
      "labSubbed-page:visit",
    ]
  },
  LAB_STUDENT: {
    static: [
      "labRegister-page:visit",
      "labGuide-page:visit",
      "labSubbed-page:visit",
    ]
  },
  ADMIN: {
    static: [
      "teacherArea:visible",
      "labRequest-page:visit",
      "labRequest-page:confirm",
      "labRegister-page:visit",
      "adminLabArea:visible",
      "labConsolle-page:visit",
      "labGuide-page:visit",
      "labExamRequest-page:visit",
      "labExamRequest-page:confirm",
      "labSubbed-page:visit",
    ]
  }
};

export default rules;
