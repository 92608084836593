import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GestioneLib from './GestioneLib';


function LabInventoryPage(props) {
    var ret;

    ret = (
        <React.Fragment >
            <br />
            <Tabs defaultActiveKey="l0" id="lib-inventory">
                <Tab eventKey="l0" title="Inventario LIB (Laboratori Fisici)">
                    <GestioneLib />
                </Tab>
            </Tabs>

            <br />
            <br />
        </React.Fragment>




    );

    return ret;


}

export default LabInventoryPage;