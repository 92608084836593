import React  from 'react';

const footerStyle = {
    backgroundColor: "#a71e3c",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "10px",
    //position: "fixed",
    left: "0",
    bottom: "0",
    //height: "60px",
    width: "100%"
  };
  
  const phantomStyle = {
    display: "block",
    padding: "10px",
    //height: "60px",
    width: "100%"
  };

  const aStyle = {
    color: "white",
    fontfamily: "PT Sans NarroW"
  }
  

function Footer(props) {
    return(
        <div>
            <div style={phantomStyle} />
            <div  style={footerStyle}>
                <p className="footer_text footer_updated">&copy;2020 Universit&agrave; degli Studi di Milano-Bicocca | Sistemi Informativi | Piazza dell'Ateneo Nuovo, 1 - 20126, Milano | tel. 02 6448 1 | Casella PEC: 
                    <a style={aStyle} href="mailto:ateneo.bicocca@pec.unimib.it" title="Scrivi alla PEC di Ateneo">ateneo.bicocca@pec.unimib.it</a> P.I. 12621570154 &nbsp;| 
                    <strong><a style={aStyle} href="mailto:redazioneweb@unimib.it">redazioneweb@unimib.it</a></strong> &nbsp;| <a style={aStyle} href="https://www.unimib.it/privacy">Privacy </a> 
                    &nbsp;| ver. {process.env.REACT_APP_VERSION} | {process.env.NODE_ENV}
                </p>
            </div>
        </div>
    );
  }
  
  export default Footer;