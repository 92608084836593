import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import RegisterLabPage from "./RegisterLabPage";


function RegisterLab() {
    const { authInfo } = useAuth();
    const { apiAuthResponse, apiAuthName, apiAuthSurname, apiAuthGroups } = authInfo;

    var myHome;
    let { handle } = useParams();

    if (!apiAuthGroups) {
        myHome = null;
    } else {
        myHome = (
            <RegisterLabPage
                title={"Registration Lab"}
                handle={handle}
                apiAuthResponse={apiAuthResponse}
                apiAuthName={apiAuthName}
                apiAuthSurname={apiAuthSurname}
                apiAuthGroups={apiAuthGroups}
            />

        )

    }

    return myHome;
}

export default RegisterLab;