import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player'

function PillolePage(props) {

    var ret;

    ret = (
        <React.Fragment >
            <br />
            <Tab.Container id="left-tabs-pillole" defaultActiveKey="l0">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="l0">Intro LIBaaS</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="l1">Richiedere un nuovo Laboratorio</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="l2">Pianificare un turno per un Laboratorio esistente</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="l3">Verificare lo stato di un Laboratorio e renderlo disponibile per la lezione</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="l4">Collaboration - condivisione di una VM esclusivamente con il docente</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="l5">Guida studente</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="l0">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player bottom-right'
                                        url='./video/libaas_intro.mp4'
                                        width='60%'
                                        height='60%'
                                        controls={true}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="l1">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player bottom-right'
                                        url='./video/richiesta_laboratorio.mp4'
                                        width='60%'
                                        height='60%'
                                        controls={true}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="l2">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player bottom-right'
                                        url='./video/richiesta_turno_laboratorio.mp4'
                                        width='60%'
                                        height='60%'
                                        controls={true}

                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="l3">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player bottom-right'
                                        url='./video/verifica_stato_e_pubblicazione_laboratorio.mp4'
                                        width='60%'
                                        height='60%'
                                        controls={true}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="l4">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player bottom-right'
                                        url='./video/libaas_collaboration.mp4'
                                        width='60%'
                                        height='60%'
                                        controls={true}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="l5">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player bottom-right'
                                        url='./video/guida_studenti.mp4'
                                        width='60%'
                                        height='60%'
                                        controls={true}
                                    />
                                </div>
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <br />
            <br />
        </React.Fragment>



    );

    return ret;

}

export default PillolePage;
