import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';


function SelectLab(props) {
    const [baseImages, setBaseImages] = useState([]);
    const [selected, setSelected] = useState();
    var ret;


    useEffect(() => {

        function fetchDefaultData() {
            const tempArray = [];
            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_BOOKABLE_LAB, {
                    credentials: 'include',
                    method: "GET"
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        data.forEach((element) => {
                            tempArray.push(
                                {
                                    label: `[${element.baseImage.os}] ${element.labName}` +
                                        ` - ${element.labDescription}`,
                                    value: {
                                        //idImage: `${element.idImage}`,
                                        idLab: `${element.idLab}`,
                                        imageOs: `${element.baseImage.os}`,
                                        imageName: `${element.baseImage.name}`,
                                        imageSize: `${element.baseImage.size}`,
                                        imageSizeCustomizable: `0`,
                                        imageFeed: `${element.baseImage.feed}`,
                                        imageFeedBundle: `${element.baseImage.feedBundle}`,
                                        imageDes: `${element.baseImage.des}`,
                                        imageType: `${element.baseImage.imageType}`,
                                        destination: {
                                            id: `${element.destId}`,
                                            des: `${element.destDes}`,
                                            url: `${element.destUrl}`,
                                        },
                                        usageData: `${JSON.stringify(element.usageData)}`,
                                        teachers: `${JSON.stringify(element.teachers)}`,
                                    }
                                });
                        });
                    })
                    .then(() => {
                        setBaseImages(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });
        }

        fetchDefaultData();


        if (!props.isBookable) {
            setSelected(null);
        }


    }, [props.isBookable]);

    function onSelectChange(selectedOption) {
        if (selectedOption) {
            props.setBaseImage(selectedOption.value);
            props.setIsBookable(true);
            setSelected(selectedOption);
        }
    };

    ret = (
        <Form.Group controlId="formLabRequestBaseImage" style={{ width: "85%" }}>
            <Form.Label>Pianificazione lezioni in un Laboratorio esistente</Form.Label>
            <Select
                value={selected}
                options={baseImages}
                isSearchable={false}
                placeholder="Selezionare il laboratorio"
                onChange={(e) => {
                    onSelectChange(e);
                }}
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={props.baseImage}
                required={true}
            />
            <br />
        </Form.Group>

    );

    return ret;
}

export default SelectLab;