// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState({
    apiAuthResponse: '',
    apiAuthName: '',
    apiAuthSurname: '',
    apiAuthGroups: [],
  });

  return (
    <AuthContext.Provider value={{ authInfo, setAuthInfo }}>
      {children}
    </AuthContext.Provider>
  );
};