import React, { useState } from 'react';
import SelectBaseDc from './SelectBaseDc';
import SelectBaseOs from './SelectBaseOs';
import SelectBaseKind from './SelectBaseKind';
import SelectBaseImage from './SelectBaseImage';


function MultipleStepBaseImage(props) {
    const [statusForm, setStatusForm] = useState({
        step: 1,
        datacenter: '',
        os: '',
        kindImage: '',
    });

    const { step } = statusForm;

    var ret = null;

/*
    useEffect(() => {
        console.log("debuggg: " + JSON.stringify(statusForm))
    }, [statusForm]);
*/

    const prevStep = () => {
        var myElem = {
            ...statusForm,
            step: step - 1,
        }

        setStatusForm(myElem)
    }

    const handleSelectDc = (myDc) => {
        var myElem = {
            ...statusForm,
            datacenter: myDc,
            step: step + 1,
        }

        setStatusForm(myElem);
    }

    const handleSelectOs = (myOs) => {
        const myElem = {
            ...statusForm,
            os: myOs,
            step: step + 1
        }

        setStatusForm(myElem)
    }

    const handleSelectKindImage = (myKindImage) => {
        var myElem = {
            ...statusForm,
            kindImage: myKindImage,
            step: step + 1,
        }

        setStatusForm(myElem)
        props.onCompletion(); // Call the onCompletion prop
    }


    switch (step) {
        case 1:
            return <SelectBaseDc
                handleSelectDc={handleSelectDc}
            />
        case 2:
            return <SelectBaseOs
                prevStep={prevStep}
                handleSelectOs={handleSelectOs}
                statusForm={statusForm}
            />
        case 3:
            return <SelectBaseKind
                prevStep={prevStep}
                handleSelectKindImage={handleSelectKindImage}
                statusForm={statusForm}
            />
        case 4:
            return <SelectBaseImage
                prevStep={prevStep}
                setBaseImage={props.setBaseImage}
                baseImage={props.baseImage}
                setIsBookable={props.setIsBookable}
                isBookable={props.isBookable}
                statusForm={statusForm}
            />
        default:
    }

    console.log("debug: " + JSON.stringify(statusForm))




    return ret;
}

export default MultipleStepBaseImage;