import React from 'react';
import Can from "../Can";
import LabConsolleSchedulePage from './LabConsolleSchedulePage';

function LabConsolleSchedule(props) {
    var myHome;

    if (!props.apiAuthGroups) {
        myHome = (
            <React.Fragment>
                <h2>Non autorizzato</h2>
            </React.Fragment>
        )
    } else {
        myHome = (
            <Can
                role={props.apiAuthGroups}
                perform="labConsolle-page:visit"
                yes={() => (
                    <LabConsolleSchedulePage
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        isAdmin={true}
                    />

                )}
                no={() => null}
            />
        )
    }


    return myHome;
}

export default LabConsolleSchedule;