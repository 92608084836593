import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import {Button} from '@mui/material';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import DropLabConfirm from '../scheduleLab/DropLabConfirm';

import '../../css/table-sort.css';




function LabConsolleDroppedPage(props) {
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [statusRequest, setStatusRequest] = useState();
    const [adSel, setAdSel] = useState(props.labProps.adSel);

    var ret = null;

    useEffect(() => {
        //console.log('adSel has changed', adSel);
    }, [adSel]);


    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var url;

        url = process.env.REACT_APP_URL_DROP_LAB;
        console.log("url: " + url)

        const payload = (
            {
                idLab: props.labProps.idLab,
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log("payload: " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                    setIsSubmitble(true);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    ret = (
        <React.Fragment>
            <h2 className="title-page">Cancella Laboratorio Virtuale {props.labProps.labName}</h2>

            <Button
                size="sm"
                variant="contained"
                onClick={() => { props.setIsShowDropLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>
            <hr />
            
            <Form onSubmit={onSubmit}>
                
                <Form.Group style={{ width: "100%" }}>
                    <Form.Label>E-mail richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.email}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Identificativo Laboratorio</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.idLab}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Tipologia Laboratorio</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.imageType}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Sistema Operativo</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.imageOs}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Immagine base</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.imageName}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Attività didattica collegata al laboratorio virtuale</Form.Label>
                    <Card style={{ padding: '1rem' }}>
                    <Form.Label>Descrizione attività didattica</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${adSel.anaAfDescIta}`}
                        readOnly
                    />
                    <Form.Label>Descrizione modulo</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${adSel.anaModDescIta}`}
                        readOnly
                    />
                    <Form.Label>Codice Easy</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${adSel.easyAdCod}`}
                        readOnly
                    />
                    </Card>
                    <br />  
                </Form.Group>

                <div>
                    <DropLabConfirm
                        isSubmitted={isSubmitted}
                        isSubmitble={isSubmitble}
                        statusRequest={statusRequest}
                        apiAuthGroups={props.apiAuthGroups}
                        buttonDescription={"Cancella il laboratorio"}
                    />
                    <br />
                    <ClipLoader
                        size={35}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </div>

            </Form>

            <br />

            <hr />
            <Button
                size="sm"
                variant="contained"
                onClick={() => { props.setIsShowDropLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>


        </React.Fragment>
    );





    if (props.labProps) {
        return ret;
    } else {
        return (
            <Button
                size="sm"
                variant="contained"
                onClick={() => { props.setIsShowScheduleLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>
        );
    }

}

export default LabConsolleDroppedPage;
