import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

function Summary({ stepsData }) {
  return (
    <Card sx={{ display: 'flex', marginBottom: '20px', width: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="h6" component="div">
            Summary
          </Typography>
          {Object.entries(stepsData).map(([step, data], index) => (
            <div key={index}>
              <Typography variant="subtitle1" component="h2">
                {step}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data}
              </Typography>
            </div>
          ))}
        </CardContent>
        <CardActions>
          {/* Add any actions for the card here */}
        </CardActions>
      </Box>
    </Card>
  );
}

export default Summary;