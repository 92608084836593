import React from 'react';

function Header(props) {

    return (
        <header id="navbar" role="banner" className="navbar container navbar-default" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="navbar-header col-xs-12 col-sm-12 col-md-12 col-lg-2">
                <a className="logo navbar-btn pull-left" href="https://www.unimib.it/" title="Torna alla home page di Università degli Studi di Milano-Bicocca">
                    <img src="/logo_unimib.png" alt="Logo Università degli Studi di Milano-Bicocca" />
                </a>
            </div>
            <h1 className="block-title header">
                <a className="bicocca" href="https://www.unimib.it/" title="Torna alla home page di Università degli Studi di Milano-Bicocca">Università degli Studi di Milano-Bicocca
                </a>
            </h1>
        </header>
    );
}

export default Header;