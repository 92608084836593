import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button} from '@mui/material';
import Container from 'react-bootstrap/Container';
import Select from 'react-select'


function SelectBaseImage(props) {
    const [baseImages, setBaseImages] = useState([]);
    const [selected, setSelected] = useState();
    const [textBarContent, setTextBarContent] = useState('');
    const [imageName, setImageName] = useState('');

    const back = (e) => {
        e.preventDefault();
        props.prevStep();
    }

    var ret;

    const fetchRepoPck = (feedBundle) => {

        fetch(process.env.REACT_APP_URL_REPO +
            '?feedName=' + feedBundle +
            '&packageNameQuery=', {
            credentials: 'include',
            method: "GET"
        })
            .then((resp) => {
                return resp.json()
            })
            .then((data) => {
                let resultString = '';
                data.forEach((element) => {
                    resultString += `${element.name}, `;
                });
                // Remove the trailing comma and space
                resultString = resultString.slice(0, -2);
                setTextBarContent(resultString);
            })
            .catch((error) => {
                console.log(error, "Errore!")
            });
    }


    useEffect(() => {
        function fetchDefaultData() {
            var formBody = [];
            const tempArray = [];
            const payload = {
                datacenter: props.statusForm.datacenter,
                os: props.statusForm.os,
                kindImage: props.statusForm.kindImage
            };

            formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_BASE_IMAGES, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        data.forEach((element) => {
                            // Da funzionalità di richiesta laboratorio per il docente, sono visibili solo
                            // le immagini di tipo template (non exam)
                            if (element.imageType === "template") {
                                tempArray.push(
                                    {
                                        label: `[${element.imageOs}] ${element.imageName}` +
                                            ` - ${element.imageDes}`,
                                        value: {
                                            idImage: `${element.idImage}`,
                                            imageOs: `${element.imageOs}`,
                                            imageName: `${element.imageName}`,
                                            imageSize: `${element.imageSize}`,
                                            imageSizeCustomizable: `${element.imageSizeCustomizable}`,
                                            feed: `${element.imageFeed}`,
                                            feedBundle: `${element.imageFeedBundle}`,
                                            imageDes: `${element.imageDes}`,
                                            imageType: `${element.imageType}`,
                                            destination: {
                                                id: `${element.destId}`,
                                                des: `${element.destDes}`,
                                                url: `${element.destUrl}`,
                                            }
                                        }
                                    });
                            }
                        });
                    })
                    .then(() => {
                        setBaseImages(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });
        }

        fetchDefaultData();

        if (props.isBookable) {
            setSelected(null);
        }


    }, [props.isBookable, props.statusForm]);

    function onSelectChange(selectedOption) {
        if (selectedOption) {
            props.setBaseImage(selectedOption.value);
            fetchRepoPck(selectedOption.value.feedBundle);
            setImageName(selectedOption.value.imageName);
            props.setIsBookable(false);
            setSelected(selectedOption);
        }
    };

    ret = (
        <Container>
            <Form>
                <Form.Group controlId="formLabRequestBaseImage" style={{ width: "100%" }}>
                    <Form.Label>Wizard per la scelta dell'immagine base</Form.Label>

                    <Card style={{ padding: '3rem' }}>


                        <Row xs={1} md={2} className="g-4">
                            <Col md={10}>
                                <Form.Group controlId="formLabRequestBaseImage" style={{ width: "85%" }}>
                                    <Form.Label>Immagine da personalizzare</Form.Label>

                                    <Select
                                        value={selected}
                                        options={baseImages}
                                        isSearchable={false}
                                        placeholder="Selezionare l'immagine base"
                                        onChange={(e) => {
                                            onSelectChange(e);
                                        }}
                                    />
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        value={props.baseImage}
                                        required={true}
                                    />
                                    <br />
                                    <Form.Label>Pacchetti</Form.Label>
                                    <textarea value={textBarContent} readOnly style={{ width: '100%', minHeight: '50px', maxHeight: '200px' }} />
                                    <br />
                                    <Form.Label>Nome Immagine</Form.Label>
                                    <input value={imageName} readOnly style={{ width: '100%', minHeight: '50px', maxHeight: '200px' }} />

                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Row>
                                    <div className="shadow-none p-1 mb-5 bg-light rounded w-100" >1. Datacenter
                                        <br />
                                        <b>{props.statusForm.datacenter === 'unimib' ? 'Unimib' : 'Cloud'}</b>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="shadow-none p-1 mb-5 bg-light rounded w-100">2. Sistema operativo
                                        <br />
                                        <b>{props.statusForm.os === 'linux' ? 'Linux' : 'Windows'}</b>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="shadow-none p-1 mb-5 bg-light rounded w-100">3. Tipo immagine
                                        <br />
                                        <b>{props.statusForm.kindImage === 'base' ? 'Base' : 'Personalizzata'}</b>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="shadow-lg p-1 mb-5 bg-white rounded w-100">4. Selezione immagine
                                        <br/><b>{selected ? selected.value.imageName : null}</b>
                                    </div>
                                </Row>
                                <Row>
                                    <Button md={3} variant="contained" onClick={back} >Indietro</Button>
                                </Row>
                            </Col>

                        </Row>

                    </Card>

                    <br />
                </Form.Group>

            </Form>
        </Container >



    );

    return ret;
}

export default SelectBaseImage;