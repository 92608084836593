import React, { useState, useRef, useEffect } from 'react';

import SelectAd from './SelectAd';
import SelectRepoPkg from './SelectRepoPkg';
import ReqLabConfirm from './ReqLabConfirm';
import MultipleStepBaseImage from './MultipleStepBaseImage';
import SelectSize from './SelectSize';
import LabName from './LabName';
import LabDescription from './LabDescription';
import SelectAllocationMode from './SelectAllocationMode';
import { Form, Container } from 'react-bootstrap';
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import '../../css/table-sort.css';
import Summary from './Summary';


function ReqExamLabPage(props) {
    const firstRender = useRef(true);

    const [adSel, setAdSel] = useState();
    const [coperEasy, setCoperEasy] = useState();
    const [coperTeacher, setCoperTeacher] = useState([]);
    const [repoPkgBundleSel, setRepoPkgBundleSel] = useState([]);
    const [repoPkgSel, setRepoPkgSel] = useState([]);
    const [installedPackages] = useState([]);
    const [labName, setLabName] = useState("");
    const [labDescription, setLabDescription] = useState("");
    const [labNotes, setLabNotes] = useState();
    const [labOtherActivities, setLabOtherActivities] = useState("");
    const [baseImage, setBaseImage] = useState({});
    const [isBookable, setIsBookable] = useState(false);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isPkgListModified, setIsPkgListModified] = useState(false);
    const [statusRequest, setStatusRequest] = useState();
    const [isLabnameChecked, setIsLabnameChecked] = useState(true);
    const [isLabnameSpellChecked, setIsLabnameSpellChecked] = useState(true);
    const [isLabdescriptionSpellChecked, setIsLabdescriptionSpellChecked] = useState(true);
    const [isAdSpellChecked, setIsAdSpellChecked] = useState(true);
    const [students, setStudents] = useState([]);
    const [physicalLabs, setPhysicalLabs] = useState([]);
    const [labType] = useState("exam");
    const [activeStep, setActiveStep] = useState(0);
    const [isSelectAdCompleted, setSelectAdCompleted] = useState(false);
    const [isLabNameCompleted, setLabNameCompleted] = useState(false);
    const [isLabDescCompleted, setLabDescCompleted] = useState(false);
    const [stepsData, setStepsData] = useState({});
    const [checkedImportEasy, setImportEasy] = useState(true);

    const updateStepData = (step, data) => {
        setStepsData(prevStepsData => ({
            ...prevStepsData,
            [step]: data,
        }));
    };

    
    const importEasyHandler = (event) => {
        setImportEasy(event.target.checked);
    }

    const steps = [
        'Select Base Image',
        'Select Repo Package',
        'Select Size',
        'Select Ad',
        'Lab Name',
        'Lab Description',
        'Note aggiuntive',
        'Allocazione postazioni esame',
        'Confirm'
    ];

    const handleNext = () => {
        // get the data of the current step
        let currentStepData;
        switch (activeStep) {
            case 0:
                let imgName = baseImage.imageName
                currentStepData = imgName;
                break;
            case 1:
                let valueString = repoPkgSel.map(item => item.value).join(', ');
                currentStepData = valueString ? valueString : "Nessun pacchetto addizionale";
                break;
            case 2:
                console.log(baseImage)
                let imgSize = baseImage.imageSize;
                currentStepData = imgSize;
                break;
            case 3:
                currentStepData = adSel.anaAfCod;
                break;
            case 4:
                currentStepData = labName;
                break;
            case 5:
                currentStepData = labDescription;
                break;
            case 6:
                currentStepData = labNotes ? labNotes : "Nessuna nota aggiuntiva";
                break;
            case 7:
                let physicalLabsString = physicalLabs.join(', ');
                currentStepData = physicalLabsString
                break;
            default:
                break;
        }
        // update stepsData with the data of the current step
        updateStepData(`Step ${activeStep + 1}`, currentStepData);
        // increment activeStep
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleSelectAdCompletion = () => {
        // Code to handle the completion of the MultipleStepBaseImage component goes here
        setSelectAdCompleted(true);
    };

    const handleLabNameCompletion = () => {
        // Code to handle the completion of the MultipleStepBaseImage component goes here
        setLabNameCompleted(true);
    };

    useEffect(() => {
        const formValidation = () => {
            var isOkLabName = false;
            var isOkLabDescription = false;
            var isOkAttivitaDidattica = false;

            if (!isLabnameChecked || labName === "" || !labName.match(/^[a-z|A-Z|0-9|-]*$/)) {
                isOkLabName = false
            } else {
                isOkLabName = true
                setIsLabnameSpellChecked(true);
            }

            if (labName.length > 0 && isOkLabName === false) {
                setIsLabnameSpellChecked(false);
            } else {
                setIsLabnameSpellChecked(true);
            }

            if (labDescription === "" || !labDescription.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkLabDescription = false
            } else {
                isOkLabDescription = true
                setIsLabdescriptionSpellChecked(true);
            }

            if (labDescription.length > 0 && isOkLabDescription === false) {
                setIsLabdescriptionSpellChecked(false);
            } else {
                setIsLabdescriptionSpellChecked(true);
            }

            if (labOtherActivities === "" || !labOtherActivities.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkAttivitaDidattica = false;
            } else {
                isOkAttivitaDidattica = true;
                setIsAdSpellChecked(true);
            }

            if (labOtherActivities.length > 0 && isOkAttivitaDidattica === false) {
                setIsAdSpellChecked(false);
            } else {
                setIsAdSpellChecked(true);
            }

            if (isOkAttivitaDidattica === false && adSel !== undefined) {
                isOkAttivitaDidattica = true;
            }

            return (isOkLabName && isOkLabDescription && isOkAttivitaDidattica);
        }

        // skippo la prima renderizzazione
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setIsSubmitble(formValidation());

    }, [labName, labDescription, isLabnameChecked, labOtherActivities, adSel]);

    const usageType = () => {
        return (
            <React.Fragment>
                <Form onSubmit={onSubmit}>
                    <Container>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <Form.Group controlId="formLabRequest" style={{ width: "85%" }}>
                                <MultipleStepBaseImage
                                    setBaseImage={setBaseImage}
                                    baseImage={baseImage}
                                    setIsBookable={setIsBookable}
                                    isBookable={isBookable}
                                />
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 1 && (
                            <Form.Group controlId="formLabRequestSw" style={{ width: "85%" }}>
                                <Summary stepsData={stepsData} />
                                <SelectRepoPkg
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setRepoPkgSel={setRepoPkgSel}
                                    setRepoPkgBundleSel={setRepoPkgBundleSel}
                                    installedPackages={installedPackages}
                                    baseImage={baseImage}
                                    setIsPkgListModified={setIsPkgListModified}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>

                            </Form.Group>
                        )}
                        {activeStep === 2 && (
                            <Form.Group controlId="formLabRequest" style={{ width: "85%" }}>
                                <Summary stepsData={stepsData} />
                                <SelectSize
                                    setBaseImage={setBaseImage}
                                    baseImage={baseImage}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>
                            </Form.Group>

                        )}
                        {activeStep === 3 && (
                            <Form.Group controlId="formLabRequest" style={{ width: "85%" }}>
                                <Summary stepsData={stepsData} />
                                <SelectAd
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setAdSelected={setAdSel}
                                    setCoperEasy={setCoperEasy}
                                    isAdSpellChecked={isAdSpellChecked}
                                    setCoperTeacher={setCoperTeacher}
                                    labOtherActivitiesSelected={setLabOtherActivities}
                                    labName={labName}
                                    onCompletion={handleSelectAdCompletion}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext} disabled={!isSelectAdCompleted}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 4 && (
                            <Form.Group style={{ width: "100%" }}>
                                <Summary stepsData={stepsData} />
                                <LabName
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    adSel={adSel}
                                    labNameSelected={setLabName}
                                    labName={labName}
                                    setIsLabnameChecked={setIsLabnameChecked}
                                    isLabnameSpellChecked={isLabnameSpellChecked}
                                    isSubmitted={isSubmitted}
                                    onCompletion={handleLabNameCompletion}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext} disabled={!isLabNameCompleted}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 5 && (
                            <Form.Group style={{ width: "100%" }}>
                                <Summary stepsData={stepsData} />
                                <LabDescription
                                    labDescriptionHandler={labDescriptionHandler}
                                    isLabdescriptionSpellChecked={isLabdescriptionSpellChecked}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext} disabled={!isLabDescCompleted}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 6 && (
                            <Form.Group style={{ width: "100%", marginTop: "50px" }}>
                                <Summary stepsData={stepsData} />
                                <Form.Label>Note aggiuntive</Form.Label>
                                <Form.Control style={{ width: "85%" }}
                                    as="textarea"
                                    rows="3"
                                    maxLength="2000"
                                    placeholder="Inserire eventuali note aggiuntive"
                                    onChange={labNotesHandler}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Importare il calendario da EasyEvents GDA"
                                    id="formCheckbox"
                                    checked={checkedImportEasy}
                                    onChange={importEasyHandler}
                                />
                                <Form.Control
                                    type="text"
                                    readOnly
                                    defaultValue="Abilitando la checkbox verranno importati tutti gli eventi associati al docente dal sistema GDA."
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 7 && (
                            <Form.Group style={{ width: "100%" }}>
                                <Summary stepsData={stepsData} />
                                <SelectAllocationMode
                                    apiAuthResponse={props.apiAuthResponse}
                                    setStudents={setStudents}
                                    students={students}
                                    setPhysicalLabs={setPhysicalLabs}
                                    physicalLabs={physicalLabs}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 8 && (
                            <Form.Group style={{ width: "100%", marginTop: "50px" }}>
                                <Summary stepsData={stepsData} />
                                <ReqLabConfirm
                                    isSubmitted={isSubmitted}
                                    isSubmitble={isSubmitble}
                                    statusRequest={statusRequest}
                                    apiAuthGroups={props.apiAuthGroups}
                                    isPkgListModified={isPkgListModified}
                                    buttonDescription={"Istanzia laboratorio"}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                            </Form.Group>
                        )}
                    </Container>
                </Form >
            </React.Fragment >
        );


    }

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var myTeachers = [];
        var labEvents = [];

        var url;



        url = process.env.REACT_APP_URL_SAVE_REQ_LAB;

        if (coperTeacher.length > 0) {
            myTeachers = coperTeacher;
        } else {
            myTeachers = [{ email: props.apiAuthResponse, partitions: [0] }];
        }

        if (coperEasy.length > 0 && checkedImportEasy) {
            labEvents = coperEasy.map(event => ({
                ...event,
                scheduleType: "easy",
                teacher: { email: props.apiAuthResponse, partition: 0 }
            }));
        } else {
            labEvents = [];
        }

        const payload = (
            {
                idLab: baseImage.idLab,
                email: props.apiAuthResponse,
                //teachers: [{ email: props.apiAuthResponse, partitions: [0] }],
                //teachers: coperTeacher,
                teachers: myTeachers,
                labName: labName,
                labDescription: labDescription,
                labType: labType,
                repoPkgBundleSel: repoPkgBundleSel,
                repoPkgSel: repoPkgSel,
                adSel: adSel,
                labOtherActivities: labOtherActivities,
                labNotes: labNotes,
                baseImage: {
                    os: baseImage.imageOs,
                    name: baseImage.imageName,
                    des: baseImage.imageDes,
                    size: baseImage.imageSize,
                    feed: baseImage.feed,
                    feedBundle: baseImage.feedBundle,
                    imageType: baseImage.imageType,
                    destination: baseImage.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //quota: quota,
                    schedulerLab: labEvents,
                },
                students: students,
                physicalLabs: physicalLabs,
            }
        );

        formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

        setTimeout(async () => {
            console.log("payload (ReqExamLabPage): " + JSON.stringify(payload));
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }



    const labDescriptionHandler = (event) => {
        setLabDescription(event.target.value);
        setLabDescCompleted(true);
    }

    const labNotesHandler = (event) => {
        setLabNotes(event.target.value);
    }

    return (
        <React.Fragment>
            <h2 className="title-page">Richiesta Laboratorio Virtuale per esame</h2>
            <p>Da questa pagina è possibile attivare un Laboratorio Virtuale in modalità Esame</p>



            {usageType(baseImage)}


        </React.Fragment>
    );

}

export default ReqExamLabPage;
