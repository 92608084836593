import React, { useState, useEffect, useMemo } from 'react';
import { Form, Col, Row, Modal } from 'react-bootstrap';
import {Button} from '@mui/material';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import '../../css/table-sort.css';

function LabConsolleModifyAF(props) {

    const [dataLib, setDataLib] = useState([]);
    const [searchLib, setSearchLib] = useState("");
    //const [chiave, setChiave] = useState("");
    const [reloadData, setReloadData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rowSelection, setRowSelection] = useState({});

    const {setAdSel, labProps} = props;    


    const searchLibHandler = (lib) => {
        setSearchLib(lib.target.value);
    }


    useEffect(() => {
        var formBody = [];
        var payload = {
            //key: chiave,
            key: "",
            value: searchLib
        }

        function fetchData() {
            setTimeout(async () => {
                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_GET_AFS_BY_KEY, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataLib(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });
        }

        setDataLib([])
        setReloadData(false);

        fetchData();

    }, [searchLib, reloadData]);


    function updateAdSel(payload) {
        var formBody = [];
        setTimeout(async () => {
            formBody.push("payload=" + JSON.stringify(payload));
            await fetch(process.env.REACT_APP_URL_REPLACE_ADSEL, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        //var formBody = [];
        //var url;
    }



    const columnsLib = useMemo(
        () => [
            {
                accessorKey: 'docPostaElettronica',
                header: 'E-mail Docente',
                size: 120,
            },
            {
                accessorKey: 'Codice Attività Formativa',
                header: 'Turno',
                size: 90,
            },
            {
                accessorKey: 'anaAfDescIta',
                header: 'Descrizione Attività Formativa',
                size: 150,
            },
            {
                accessorKey: "anaModCod",
                header: "Codice Modulo",
                size: 120,
            },
            {
                accessorKey: "anaModDescIta",
                header: "Descrizione Modulo",
                size: 120,
            },
            {
                accessorKey: "easyAdCod",
                header: "Codice Easy",
                size: 120,
            },
        ],
        [],
    );



    useEffect(() => {
        function handleConfirm(row) {
            // Handle the confirm action here.
            console.log('Confirm clicked for row: ', row);
            var payload = {
                adSel: row,
                idLab: labProps.idLab
            }
            updateAdSel(payload);
            const newAdSel = row;
            setAdSel(newAdSel);
            setShowModal(false);
        }
        const selectedRows = Object.keys(rowSelection)
            .filter(index => rowSelection[index]) // Get the indices of the selected rows
            .map(index => dataLib[index]); // Map each index to the email of the corresponding student

        const selectedAfRow = selectedRows[0]; // Get the first selected row

        if (selectedAfRow) { // Check if selectedAfRow is not null or undefined
            handleConfirm(selectedAfRow);
        }
    }, [rowSelection, dataLib, setAdSel, labProps]);

    const table =
        useMaterialReactTable({
            columns: columnsLib,
            data: dataLib || [],
            enableGlobalFilterModes: true,
            enablePagination: true,
            enableBottomToolbar: true,
            paginationDisplayMode: 'pages',
            pageSize: 5,
            enableColumnPinning: true,
            enableRowNumbers: false,
            enableRowVirtualization: true,
            enableRowSelection: true,
            enableMultiRowSelection: false,
            enableSelectAll: true,
            onRowSelectionChange: setRowSelection,
            state: { rowSelection }
        });


    return (
        <React.Fragment>
            <br />
            <Button variant="contained" onClick={() => setShowModal(true)} style={{ width: '30%' }}>
                Modifica attività didattica del laboratorio
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Modifica attività didattica del laboratorio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="searchLib" style={{ width: "85%" }}>
                            <Form.Label>Modifica attività didattica del laboratorio</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Text className="text-muted">Inserire l'email del Docente</Form.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Control style={{ width: "100%" }}
                                        required
                                        type="text"
                                        onChange={searchLibHandler}
                                        value={searchLib}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <div className="table-responsive">
                        <MaterialReactTable table={table} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>


            <br />
        </React.Fragment>
    );
}

export default LabConsolleModifyAF;