import React, { useState, useEffect } from 'react';
import CookieConsent from "react-cookie-consent";

import './css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import BootstrapNavbar from './components/BootstrapNavbar';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  const [apiAuthResponse, setAuthResponse] = useState();
  const [apiAuthName, setAuthName] = useState();
  const [apiAuthSurname, setAuthSurname] = useState();
  const [apiAuthGroups, setAuthGroups] = useState([]);
  const [stuMatricola, setStuMatricola] = useState();
  const [stuCodFis, setStuCodFis] = useState();
  const [stuIndiRes, setStuIndiRes] = useState();
  const [stuIndiDom, setStuIndiDom] = useState();



  useEffect(() => {
    const fetchData = async () => {
      var tempName;
      var aliasName;
      await fetch(process.env.REACT_APP_URL_AUTH_VERIFYTOKEN, { credentials: 'include' })
        .then(res => res.json())
        .then(res => {
          if (res.user && res.groups) {
            setAuthResponse(res.user.email);
            setAuthSurname(res.user.lastName);
            setAuthGroups(res.groups);
            tempName = res.user.firstName;
          }
        });


      setTimeout(async () => {
        await fetch(process.env.REACT_APP_URL_STUDENT_ADDRESS, {
          credentials: 'include',
          method: "GET"
        })
          .then((resp) => {
            return resp.json()
          })
          .then((data) => {
            if (data[0]) {
              aliasName = data[0].nomeAlias;
              setStuMatricola(data[0].matricola);
              setStuCodFis(data[0].codFis);
              setStuIndiRes({
                viaRes: `${data[0].viaRes}`,
                numCivRes: `${data[0].numCivRes}`,
                capRes: `${data[0].capRes}`,
                comuneRes: `${data[0].comuneRes}`,
                provRes: `${data[0].provRes}`,
                frazRes: `${data[0].frazRes}`,
                regioRes: `${data[0].regioRes}`,
                citstraRes: `${data[0].citstraRes}`,
                nazioneRes: `${data[0].nazioneRes}`,
                forzaSpedPosta: `${data[0].forzaSpedPosta}`
              });
              setStuIndiDom({
                viaDom: `${data[0].viaDom}`,
                numCivDom: `${data[0].numCivDom}`,
                capDom: `${data[0].capDom}`,
                comuneDom: `${data[0].comuneDom}`,
                provDom: `${data[0].provDom}`,
                frazDom: `${data[0].frazDom}`,
                regioDom: `${data[0].regioDom}`,
                citstraDom: `${data[0].citstraDom}`,
                nazioneDom: `${data[0].nazioneDom}`
              });
              return (data[0].nomeAlias);
            } else {
              return null;
            }
          })
          .then((data) => {
            (data ? setAuthName(aliasName) : setAuthName(tempName))
          })
          .catch((error) => {
            console.log(error, "Errore!")
          });
      });
    };

    fetchData();
  }, []);


  return (
    <div className="App">
      <div className="Site-content">
        <Header />
        <AuthProvider>
          <BootstrapNavbar
            apiAuthResponse={apiAuthResponse}
            apiAuthName={apiAuthName}
            apiAuthSurname={apiAuthSurname}
            apiAuthGroups={apiAuthGroups}
            stuMatricola={stuMatricola}
            stuCodFis={stuCodFis}
            stuIndiRes={stuIndiRes}
            stuIndiDom={stuIndiDom}
          />
        </AuthProvider>
        <Footer />
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accetta"
        cookieName="LIBaaSConsentCookie"
        style={{ background: "#a71e3b" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Questo sito utilizza cookies tecnici e di profilazione, propri e di terze parti
        per consentire la corretta navigazione. Proseguendo la navigazione o confermando
        tramite il tasto "Sì, accetto" ne accetti l'utilizzo. Se vuoi saperne di più e
        leggere come disabilitarne l'uso leggi  <a className="white" href="https://www.unimib.it/privacy-cookie-policy"> Maggiori informazioni</a>

      </CookieConsent>

    </div>
  );
}

export default App;
