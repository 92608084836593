import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async'
import { FormGroup, Form } from 'react-bootstrap';


function SelectAd(props) {
  const [selOpt, setSelOpt] = useState();
  const [personalAd, setPersonalAd] = useState();

  const { setAdSelected, setCoperTeacher, setCoperEasy } = props;

  function fetchDefaultData() {
    const tempArray = [];

    setTimeout(async () => {
      await fetch(process.env.REACT_APP_URL_COPER, {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tempArray.push(
              {
                docPostaElettronica: `${element.docPostaElettronica}`,
                anaAfId: `${element.anaAfId}`,
                docIdAb: `${element.docIdAb}`,
                anaModCod: `${element.anaModCod}`,
                anaModDescIta: `${element.anaModDescIta}`,
                anaAfCod: `${element.anaAfCod}`,
                anaAfDescIta: `${element.anaAfDescIta}`,
                aaOffId: `${element.aaOffId}`,
                easyAdCod: `${element.easyAdCod}`,
              });
          });
        })
        .then(() => {
          setPersonalAd(tempArray);
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    });
  }

  const fetchTeachers = (afId) => {
    const tempTeacher = [];
    setTimeout(async () => {
      const afIdParam = afId.anaAfId;
      await fetch(process.env.REACT_APP_URL_COPER_GET_TEACHERS + '?afId=' + afIdParam, {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tempTeacher.push(
              {
                email: `${element.docPostaElettronica}`,
                partitions: [0],
              });
          });
        })
        .then(() => {
          setCoperTeacher(tempTeacher);
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    });
  }

  const fetchEasy = (easyAdCod) => {
    const tempEasy = [];
    setTimeout(async () => {
      const easyAdCodParam = easyAdCod.easyAdCod;
      await fetch(process.env.REACT_APP_URL_EASY_EVENTS + '?easyAdCod=' + easyAdCodParam, {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tempEasy.push(
              {
                start: `${element.start}`,
                end: `${element.end}`,
              });
          });
        })
        .then(() => {
          setCoperEasy(tempEasy);
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    });
  }


  function fetchDataByEmail(inputValue, callback) {
    const tempArray1 = [];
    const tempArray2 = [];
    var formBody = [];
    var payload = {
      //key: chiave,
      key: "",
      value: inputValue
    }
    setTimeout(async () => {
      formBody.push("payload=" + JSON.stringify(payload));
      await fetch(process.env.REACT_APP_URL_GET_AFS_BY_KEY, {
        credentials: 'include',
        method: "POST",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          if (data && Array.isArray(data)) {
            data.forEach((element) => {
              tempArray1.push({
                label: `Insegnamento: [${element.anaAfCod}] ${element.anaAfDescIta}` +
                  ` - Modulo: [${element.anaModCod}] ${element.anaModDescIta}`,
                value: {
                  anaAfId: `${element.anaAfId}`,
                  easyAdCod: `${element.easyAdCod}`
                }
              });
            });
            callback(tempArray1);
          }
          return data; // Return data here
        })
        .then((data) => {
          if (data && Array.isArray(data)) {
            data.forEach((element) => {
              tempArray2.push({
                docPostaElettronica: `${element.docPostaElettronica}`,
                anaAfId: `${element.anaAfId}`,
                docIdAb: `${element.docIdAb}`,
                anaModCod: `${element.anaModCod}`,
                anaModDescIta: `${element.anaModDescIta}`,
                anaAfCod: `${element.anaAfCod}`,
                anaAfDescIta: `${element.anaAfDescIta}`,
                aaOffId: `${element.aaOffId}`,
                easyAdCod: `${element.easyAdCod}`,
              });
            });
            setPersonalAd(tempArray2);
          }
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    });
  }

  function onSearchChange(selectedOption) {
    if (selectedOption) {
      setSelOpt({
        selectedOption
      });

      fetchTeachers(selectedOption.value);
      fetchEasy(selectedOption.value);
      props.onCompletion();

    }
  };

  useEffect(() => {
    fetchDefaultData();
  }, []);


  useEffect(() => {
    if (selOpt) {
      const myElem = personalAd.find(
        (element) => {
          var aux = null;
          if (element.anaAfId === selOpt.selectedOption.value.anaAfId) {
            aux = element;
          }
          return aux;
        }
      );

      setAdSelected(myElem);

    }

  }, [selOpt, personalAd, setAdSelected])



  var ret;

  ret = (
    <React.Fragment >
      <Form.Label>Attività didattica</Form.Label> <br />

      <Form.Text className="text-muted">
        Digita l'email di un docente per selezionare un'insegnamento.
      </Form.Text>
      <div>
        <FormGroup>
          <AsyncSelect
            //value={this.state.selectedOption.value}
            loadOptions={fetchDataByEmail}
            onChange={(e) => {
              onSearchChange(e);
            }}
            defaultOptions={true}
          />
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0 }}
            value={selOpt}
            required={true}
          />
        </FormGroup>
      </div>
    </React.Fragment >
  );
  //}

  return ret;



}

export default SelectAd;
