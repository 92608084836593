import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Can from "../Can";
import ReqLabConfirm from './ReqLabConfirm';
import SelectLab from './SelectLab';
import SelectSize from './SelectSize';
import ClipLoader from 'react-spinners/ClipLoader';
import SchedulerLab from "./SchedulerLab";
import Alert from 'react-bootstrap/Alert';


function ScheduleLabPage(props) {
    const firstRender = useRef(true);

    const [baseImage, setBaseImage] = useState({});
    const [labEvents, setLabEvents] = useState([]);
    const [isBookable, setIsBookable] = useState(false);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [statusRequest, setStatusRequest] = useState();


    useEffect(() => {
        const formValidation = () => {

            return true;
        }

        // skippo la prima renderizzazione
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setIsSubmitble(formValidation());

    }, []);

    const demoMessage = () => {
        var message = (
            <Can
                role={props.apiAuthGroups}
                perform="labRequest-page:confirm"
                yes={() => (null)}
                no={() => (
                    <Alert variant="warning">
                        <Alert.Heading>Funzionalità di richiesta pianificazione Laboratorio Virtuale non abilitata</Alert.Heading>
                        <p>
                            La funzionalità di richiesta pianificazione Laboratorio Virtuale è in corso di attivazione e sarà a breve disponibile.
                        </p>
                    </Alert>
                )}
            />
        )

        return message;
    }

    const usageType = () => {
        if (!baseImage.imageName) {
            return (
                <React.Fragment>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </React.Fragment>
            );
        }

        if (isBookable) {

            return (
                <React.Fragment>
                    <SchedulerLab
                        email={props.apiAuthResponse}
                        baseImage={baseImage}
                        labEvents={labEvents}
                        setLabEvents={setLabEvents}
                        setIsSubmitble={setIsSubmitble}
                        isSubmitted={isSubmitted}
                        defaultView={'week'}
                        defaultDate={new Date()}
                    />
                    <br />
                    <div>
                        <ReqLabConfirm
                            isSubmitted={isSubmitted}
                            isSubmitble={isSubmitble}
                            statusRequest={statusRequest}
                            apiAuthGroups={props.apiAuthGroups}
                            buttonDescription={"Salva pianificazione"}
                        />
                        <br />
                        <ClipLoader
                            size={35}
                            color={"#a71e3b"}
                            loading={isLoading}
                        />
                    </div>

                    <br />

                </React.Fragment>
            );
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];

        var url;

        if (isBookable) {
            url = process.env.REACT_APP_URL_BOOK_LAB;
        } else {
            url = process.env.REACT_APP_URL_SAVE_REQ_LAB;
        }

        const payload = (
            {
                idLab: baseImage.idLab,
                email: props.apiAuthResponse,
                //                teachers: myTeachers,
                //                labName: labName,
                //                labDescription: labDescription,
                //                repoPkgBundleSel: repoPkgBundleSel,
                //                repoPkgSel: repoPkgSel,
                //                adSel: adSel,
                //                labOtherActivities: labOtherActivities,
                //                labNotes: labNotes,
                baseImage: {
                    os: baseImage.imageOs,
                    name: baseImage.imageName,
                    des: baseImage.imageDes,
                    size: baseImage.imageSize,
                    feed: baseImage.feed,
                    feedBundle: baseImage.feedBundle,
                    imageType: baseImage.imageType,
                    destination: baseImage.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //                    quota: quota,
                    schedulerLab: labEvents,
                }
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log("payload (ReqLabPage): " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }



    return (
        <React.Fragment>
            <h2 className="title-page">Pianificazione attività in un Laboratorio Virtuale esistente</h2>
            <p>Da questa pagina è possibile piaificare un'attività in un Laboratorio Virtuale esistente</p>

            {demoMessage()}

            <Form onSubmit={onSubmit}>
                <Form.Group style={{ width: "100%" }}>
                    <Form.Label>Nome richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.apiAuthName}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Cognome richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.apiAuthSurname}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>E-mail richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.apiAuthResponse}`}
                        readOnly
                    />
                    <br />
                </Form.Group>

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Selezione Laboratorio Virtuale per pianificare le attività</h5>
                        <Row>
                            <Col>
                                <SelectLab
                                    setBaseImage={setBaseImage}
                                    baseImage={baseImage}
                                    setIsBookable={setIsBookable}
                                    isBookable={isBookable}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                <SelectSize
                    setBaseImage={setBaseImage}
                    baseImage={baseImage}
                />

                {usageType(baseImage)}


            </Form>

        </React.Fragment>
    );

}

export default ScheduleLabPage;
