import React from 'react'


function StanzaPage(props) {

    var ret;

    ret = (
        <React.Fragment >
            <br />
            <h3>Stanza di helpdesk (137 820 4977)</h3>
            
            <p>La Stanza è disponibile dal lunedì al venerdì nelle seguenti fasce orarie:</p>
            <ul>
                <li>09:00 - 12:30</li>
                <li>14:00 - 17:30</li>
            </ul>
            <br />
            <p>Per accedere alla stanza di helpdesk
            <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_URL_WEBEXHELP}>{' '}Clicca qui</a>
            </p>
            <br />
            <br />
        </React.Fragment>

    );

    return ret;

}

export default StanzaPage;
