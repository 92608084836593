import React from 'react';
import {Button} from '@mui/material';
import Alert from 'react-bootstrap/Alert';
import Can from "../Can";

function ReqLabConfirm(props) {
    var ret;
    if (props.isSubmitble) {
        if (!props.isSubmitted) {
            ret = (
                <Can
                    role={props.apiAuthGroups}
                    perform="labRequest-page:confirm"
                    yes={() => (
                        <React.Fragment>
                            <Button variant="contained" type="submit" >
                                {props.buttonDescription}
                            </Button>
                        </React.Fragment>
                    )}
                    no={() => (
                        <React.Fragment>
                            <Alert variant="warning">

                                <Alert.Heading>Funzionalità di richiesta Laboratorio Virtuale non abilitata</Alert.Heading>
                                <p>
                                    La funzionalità di richiesta Laboratorio Virtuale è in corso di attivazione e sarà a breve disponibile.
                                </p>

                            </Alert>
                        </React.Fragment>
                    )}
                />



            );
        } else {
            if (props.statusRequest === "success") {
                ret = (
                    <React.Fragment>
                        <Alert variant="success">

                            <Alert.Heading>Richiesta Laboratorio Virtuale acquisita con successo!</Alert.Heading>
                            <p>
                                Il Laboratorio Virtuale richiesto è in lavorazione. E' possibile consultare lo stato di avanzamento
                            della richiesta da {' '}  <Alert.Link href="/PubLab">Pubblicazione Laboratori</Alert.Link>.
                        </p>

                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );
            } else {
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                            <p>
                                Contattare l'assistenza.
                    </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );

            };
        }
    } else {
        ret = (
            <React.Fragment>
                <Button variant="contained" type="submit" >
                    {props.buttonDescription}
                </Button>
            </React.Fragment>
        );
    }
    return ret;
}

export default ReqLabConfirm;