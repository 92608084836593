import React, { useState } from "react";
import PubLabDettPage from "./PubLabDettPage";
import PubLabInfiniteTable from "./PubLabInfiniteTable";
import LabConsolleDroppedPage from "../labConsolle/LabConsolleDroppedPage";
import LabConsolleClonePage from "../labConsolle/LabConsolleClonePage";
import LabConsolleScheduleDettPage from "../labConsolle/LabConsolleScheduleDettPage";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';


const queryClient = new QueryClient();


function PubLabPage(props) {
    const [selectedLabProps, setSelectedLabProps] = useState();
    const [isShowSelectedLab, setIsShowSelectedLab] = useState(false);
    const [isShowScheduleLab, setIsShowScheduleLab] = useState(false);
    const [isShowDropLab, setIsShowDropLab] = useState(false);
    const [isShowCloneLab, setIsShowCloneLab] = useState(false);


    const pubLabPage = () => {

        var retPub = null;

        if (!isShowSelectedLab && !isShowScheduleLab && !isShowDropLab && !isShowCloneLab) {
            retPub = (
                <QueryClientProvider client={queryClient}>
                    <PubLabInfiniteTable
                        apiAuthResponse={props.apiAuthResponse}
                        setSelectedLabProps={setSelectedLabProps}
                        setIsShowSelectedLab={setIsShowSelectedLab}
                        setIsShowScheduleLab={setIsShowScheduleLab}
                        setIsShowDropLab={setIsShowDropLab}
                        setIsShowCloneLab={setIsShowCloneLab}
                        isAdmin={props.isAdmin}


                    />
                </QueryClientProvider>
            );
        }

        return retPub;
    };

    const pubLabDettPage = () => {
        var retDett = null;

        if (isShowSelectedLab) {
            retDett = (
                <PubLabDettPage
                    labProps={selectedLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowSelectedLab={setIsShowSelectedLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }


    const pubLabSchedulePage = () => {
        var retDett = null;

        var myLabProps = null;

        if (selectedLabProps) {
            myLabProps = {
                email: selectedLabProps.email,
                idLab: selectedLabProps.idLab,
                imageOs: selectedLabProps.baseImage.os,
                imageName: selectedLabProps.baseImage.name,
                imageSize: selectedLabProps.baseImage.size,
                imageSizeCustomizable: 0,
                imageFeed: selectedLabProps.baseImage.feed,
                imageFeedBundle: selectedLabProps.baseImage.feedBundle,
                imageDes: selectedLabProps.baseImage.des,
                imageType: selectedLabProps.baseImage.imageType,
                adSel: selectedLabProps.adSel,
                destination: {
                    id: selectedLabProps.baseImage.destination.id,
                    des: selectedLabProps.baseImage.destination.des,
                    url: selectedLabProps.baseImage.destination.url,
                },
                //usageData: JSON.stringify(selectedLabProps.usageData),
                //teachers: JSON.stringify(selectedLabProps.teachers),
                usageData: selectedLabProps.usageData,
                teachers: selectedLabProps.teachers
            }
        }


        if (isShowScheduleLab) {
            retDett = (
                <LabConsolleScheduleDettPage
                    labProps={myLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowScheduleLab={setIsShowScheduleLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }

    const pubLabDropPage = () => {
        var retDett = null;

        var myLabProps = null;

        if (selectedLabProps) {
            myLabProps = {
                email: selectedLabProps.email,
                idLab: selectedLabProps.idLab,
                imageOs: selectedLabProps.baseImage.os,
                imageName: selectedLabProps.baseImage.name,
                imageSize: selectedLabProps.baseImage.size,
                imageSizeCustomizable: 0,
                imageFeed: selectedLabProps.baseImage.feed,
                imageFeedBundle: selectedLabProps.baseImage.feedBundle,
                imageDes: selectedLabProps.baseImage.des,
                imageType: selectedLabProps.baseImage.imageType,
                adSel: selectedLabProps.adSel,
                destination: {
                    id: selectedLabProps.baseImage.destination.id,
                    des: selectedLabProps.baseImage.destination.des,
                    url: selectedLabProps.baseImage.destination.url,
                },
                //usageData: JSON.stringify(selectedLabProps.usageData),
                //teachers: JSON.stringify(selectedLabProps.teachers),
                usageData: selectedLabProps.usageData,
                teachers: selectedLabProps.teachers
            }
        }


        if (isShowDropLab) {
            retDett = (
                <LabConsolleDroppedPage
                    labProps={myLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowDropLab={setIsShowDropLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }


    const pubLabClonePage = () => {
        var retDett = null;

        var myLabProps = null;


        if (selectedLabProps) {
            myLabProps = {
                email: selectedLabProps.email,
                idLab: selectedLabProps.idLab,
                imageOs: selectedLabProps.baseImage.os,
                imageName: selectedLabProps.baseImage.name,
                imageSize: selectedLabProps.baseImage.size,
                imageSizeCustomizable: 0,
                imageFeed: selectedLabProps.baseImage.feed,
                imageFeedBundle: selectedLabProps.baseImage.feedBundle,
                imageDes: selectedLabProps.baseImage.des,
                imageType: selectedLabProps.baseImage.imageType,
                adSel: selectedLabProps.adSel,
                destination: {
                    id: selectedLabProps.baseImage.destination.id,
                    des: selectedLabProps.baseImage.destination.des,
                    url: selectedLabProps.baseImage.destination.url,
                },
                //usageData: JSON.stringify(selectedLabProps.usageData),
                //teachers: JSON.stringify(selectedLabProps.teachers),
                usageData: selectedLabProps.usageData,
                teachers: selectedLabProps.teachers,
                repoPkgBundleSel: selectedLabProps.repoPkgBundleSel,
                repoPkgSel: selectedLabProps.repoPkgSel
            }
        }


        if (isShowCloneLab) {

            retDett = (
                <LabConsolleClonePage
                    labProps={myLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowCloneLab={setIsShowCloneLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }

    var ret = (
        <React.Fragment>
            {pubLabDettPage()}
            {pubLabSchedulePage()}
            {pubLabDropPage()}
            {pubLabClonePage()}
            {pubLabPage()}
        </React.Fragment>
    );

    return ret;


}

export default PubLabPage;
