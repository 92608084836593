import React, { useState, useEffect } from 'react';
import FormCheck from 'react-bootstrap/FormCheck';

function SwitchPubLab(props) {
    const [isSwitched, setIsSwitched] = useState(false);
    const [newStatus, setNewStatus] = useState();

    const toggleEnabled = () => {
        setIsSwitched(isSwitched === false ? true : false);
        setNewStatus(isSwitched === false ? "on" : "off");
    };

    var ret = (
        <FormCheck
            id={props.idLab}
            type="switch"
            checked={isSwitched}
            onChange={toggleEnabled}
            label="Iscrizioni abilitate"
        />)

    useEffect(() => {
        if (newStatus) {
            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_SWITCH_PUB_STATUS_LAB +
                    '?id=' + props.idLab +
                    '&status=' + newStatus
                    , {
                        credentials: 'include',
                        method: "GET"
                    })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        console.log(data.idLab + " updated! " + data.registerUrlStatus);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });
        }
    }, [props.idLab, isSwitched, newStatus]);

    useEffect(() => {
        if (props.registerUrlStatus)
            setIsSwitched(props.registerUrlStatus === "off" ? false : true);
    }, [props.registerUrlStatus]);

    return ret;
};

export default SwitchPubLab;