import React, { useState } from 'react';
import {Button} from '@mui/material';

import ClipLoader from 'react-spinners/ClipLoader';

function LabConsolleImportFromEasy(props) {
  const [isLoading, setIsLoading] = useState(0);


  const fetchEasy = (idLab) => {
    return new Promise((resolve, reject) => {
      setIsLoading(1);
      fetch(process.env.REACT_APP_URL_UPDATE_EASY_EVENTS + '?idLab=' + idLab, {
        credentials: 'include',
        method: "GET"
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          setIsLoading(0);
          resolve(response);
        })
        .catch((error) => {
          console.log(error, "Errore!")
          reject(error);
        });
    });
  }

  const fetchUsageData = (idLab) => {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_GET_USAGE_DATA + '?idLab=' + idLab, {
        credentials: 'include',
        method: "GET"
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();  // Parse the body text as JSON
        })
        .then((data) => {
          props.setLabEvents(data.schedulerLab);  // Update labEvents with the new data
          resolve(data);
        })
        .catch((error) => {
          console.log(error, "Errore!")
          reject(error);
        });
    });
  }

  const fetchData = async (idLab) => {
    await fetchEasy(idLab);
    fetchUsageData(idLab);
  }

  const handleClick = (event) => {
    event.preventDefault();
    fetchData(props.labProps.idLab);
    props.setIsSubmitble(1);
    props.setIsSubmitted(1);
    props.setStatusRequest("success");

  };

  return (
    <React.Fragment>
      {props.buttonDescription ? (
        <Button variant="contained" style={{ width: '15%' }} onClick={handleClick}>
          {props.buttonDescription}

        </Button>
      ) : null}
      <ClipLoader
        size={35}
        color={"#a71e3b"}
        loading={isLoading}
      />
    </React.Fragment>
  );
}

export default LabConsolleImportFromEasy;