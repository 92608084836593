import React from 'react';
import PubLabPage from '../pubLab/PubLabPage.js';


function LabConsollePage(props) {
    var ret;

    ret = (
        <React.Fragment >
            <br />
            <PubLabPage
                apiAuthResponse={props.apiAuthResponse}
                apiAuthName={props.apiAuthName}
                apiAuthSurname={props.apiAuthSurname}
                apiAuthGroups={props.apiAuthGroups}
                isAdmin={true}
            />
            <br />
            <br />
        </React.Fragment>




    );

    return ret;


}

export default LabConsollePage;