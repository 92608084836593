import React from 'react';
import Can from "../components/Can";
import SubbedLabPage from "./subbedLab/SubbedLabPage";

const hp = {
    //height: "300px"
}

function Home(props) {
    var myHome;

    if (!props.apiAuthResponse) {
        myHome = (
            <React.Fragment>
                <div style={hp}>
                    <h2 className="welcome">LIBaaS</h2>
                    <p>Per accedere al portale occorre autenticarsi</p>
                </div>
            </React.Fragment>
        )
    } else {

        var itemLabRequest = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <li>Richiesta Laboratori Virtuali</li>
            )}
            no={() => null}
        />

        var itemLabPub = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <li>Pubblicazione Laboratori Virtuali</li>
            )}
            no={() => null}
        />

        var itemLabAmm = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <li>Amministrazione Laboratori Virtuali</li>
            )}
            no={() => null}
        />

        var itemLabRegister = <Can
            role={props.apiAuthGroups}
            perform="labRegister-page:visit"
            yes={() => (
                <li>Registrazione Laboratori Virtuali</li>
            )}
            no={() => null}
        />

        var itemLabSubbed = <Can
            role={props.apiAuthGroups}
            perform="labSubbed-page:visit"
            yes={() => (
                <SubbedLabPage
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                />
            )}
            no={() => null}
        />

        var itemGuide = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <li>Consultazione documentazione Laboratori Virtuali</li>
            )}
            no={() => null}
        />

        if (!props.apiAuthName)
            myHome = null;
        else {
            var myStuMatricola;
            if (!props.stuMatricola)
                myStuMatricola = null;
            else
                myStuMatricola = (
                    <p><b>Matricola  {props.stuMatricola}</b></p>
                );

            myHome = (
                <React.Fragment>
                    <div style={hp}>
                        <h1 className="welcome">{props.apiAuthName} {props.apiAuthSurname}</h1>
                        {myStuMatricola}
                        <br />
                        <h3 className="servizi">Servizi disponibili</h3>
                        {itemLabRequest}
                        {itemLabPub}
                        {itemLabAmm}
                        {itemGuide}
                        {itemLabRegister}
                        {itemLabSubbed}
                    </div>
                </React.Fragment>
            )
        }
    }

    return myHome;
}

export default Home;