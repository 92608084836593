import React, { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/Overlay';

function LabDescription(props) {
    const targetBadDescriptionAlert = useRef(null);
    var ret;

    ret = (
        <React.Fragment >
            <Form.Label>Descrizione del Laboratorio Virtuale</Form.Label>
            <Form.Text className="text-muted">
            <b>Dato obbligatorio</b>
            </Form.Text>            
            <Form.Control style={{ width: "100%" }}
                type="text"
                onChange={props.labDescriptionHandler}
                placeholder="Descrizione del Laboratorio"
                ref={targetBadDescriptionAlert}
                required={true}
            />
            <Overlay transition={false} target={targetBadDescriptionAlert.current} show={!props.isLabdescriptionSpellChecked} placement="top">
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        style={{
                            backgroundColor: 'rgba(255, 100, 100, 0.85)',
                            padding: '2px 10px',
                            color: 'white',
                            borderRadius: 3,
                            ...props.style,
                        }}
                    >
                        Attenzione! La descrizione inserita contiene caratteri non consentiti.
                    </div>
                )}
            </Overlay>

        </React.Fragment >


    );

    return ret;


}

export default LabDescription;