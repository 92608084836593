import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Modal, Table } from 'react-bootstrap';
import ClipLoader from "react-spinners/ClipLoader";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Pen } from "react-bootstrap-icons";
import {Button} from '@mui/material';

import '../../css/table-sort.css';

function GestioneLib() {
    const [isLoading, setIsLoading] = useState(1)
    const [dataLib, setDataLib] = useState([]);
    const [searchLib, setSearchLib] = useState("");
    const [chiave, setChiave] = useState("");
    const [chiaveDes, setChiaveDes] = useState("Selezionare chiave");
    const [showModalLib, setShowModalLib] = useState(false);
    const [showModalPc, setShowModalPc] = useState(false);
    const [isKeySelected, setIsKeySelected] = useState(false);
    const [libPayload, setLibPayload] = useState({});
    const [libIdLabInventory, setLibIdLabInventory] = useState("");
    const [libName, setLibName] = useState("");
    const [libEdificio, setLibEdificio] = useState("");
    const [libSubnet, setLibSubnet] = useState("");
    const [libIp, setLibIp] = useState("");
    const [libPostazioni, setLibPostazioni] = useState([]);
    const [pcMacAddress, setPcMacAddress] = useState("");
    const [pcIp, setPcIp] = useState("");
    const [pcComputerName, setPcComputerName] = useState("");
    const [reloadData, setReloadData] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);

    var ret;

    const searchLibHandler = (lib) => {
        setSearchLib(lib.target.value);
    }

    const selectChiaveNome = (e) => {
        e.preventDefault();
        setChiave("nome");
        setChiaveDes("Nome LIB");
        setIsKeySelected(true);
    }

    const selectChiaveEdificio = (e) => {
        e.preventDefault();
        setChiave("edificio");
        setChiaveDes("Edificio");
        setIsKeySelected(true);
    }

    const selectChiaveSubnet = (e) => {
        e.preventDefault();
        setChiave("subnet");
        setChiaveDes("Subnet");
        setIsKeySelected(true);
    }

    const selectChiaveIp = (e) => {
        e.preventDefault();
        setChiave("ip");
        setChiaveDes("IP");
        setIsKeySelected(true);
    }

    const handleCloseModalLib = () => {
        setShowModalLib(false);
    }

    const handleDeleteModalLib = () => {
        setShowModalLib(false);
    }

    const handleSaveModalLib = () => {
        var formBody = [];
        var tempPayload = libPayload;

        tempPayload.idLabInventory = libIdLabInventory;
        tempPayload.nome = libName;
        tempPayload.edificio = libEdificio;
        tempPayload.subnet = libSubnet;
        tempPayload.ip = libIp;

        setLibPayload(tempPayload);

        setTimeout(async () => {
            setIsLoading(1);

            console.log("save: " + JSON.stringify(libPayload))
            formBody.push("payload=" + JSON.stringify(libPayload));
            await fetch(process.env.REACT_APP_URL_LIBINVENTORY_UPDATE, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });

            setReloadData(true);
            setShowModalLib(false);
            setIsLoading(0);

        });

    }

    const handleCloseModalPc = () => {
        setShowModalPc(false);
    }

    const handleDeleteModalPc = () => {
        setShowModalPc(false);
    }

    const handleSaveModalPc = () => {
        var formBody = [];
        var tempPayload = libPayload;

        libPostazioni.forEach(element => {
            if (element.computerName === pcComputerName) {
                element.macAddress = pcMacAddress;
                element.ip = pcIp;
            }
        });

        tempPayload.idLabInventory = libPayload.idLabInventory;
        tempPayload.nome = libName;
        tempPayload.edificio = libEdificio;
        tempPayload.subnet = libSubnet;
        tempPayload.ip = libIp;
        tempPayload.postazioni = libPostazioni;

        setLibPayload(tempPayload);

        setTimeout(async () => {
            setIsLoading(1);

            console.log("save: " + JSON.stringify(libPayload))
            formBody.push("payload=" + JSON.stringify(libPayload));
            await fetch(process.env.REACT_APP_URL_LIBINVENTORY_UPDATE, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });

            setReloadData(true);
            setShowModalPc(false);
            setIsLoading(0);
        });
    }


    useEffect(() => {
        var formBody = [];
        var payload = {
            key: chiave,
            value: searchLib
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_LIBINVENTORY_GET, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataLib(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataLib([])
        setReloadData(false);

        fetchData();

    }, [searchLib, chiave, reloadData]);


    const onSubmit = (e) => {
        e.preventDefault();
        //var formBody = [];
        //var url;

    }


    const modalLib = () => {
        if (!showModalLib) return;

        var retModalLib;

        retModalLib = (
            <Modal
                className={'modal-dialog-centered'}
                show={showModalLib}
                onHide={handleCloseModalLib}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifica Laboratorio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group controlId="ModalForm.ScheduleEvent1">
                            <Form.Label>ID LIB</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.idLabInventory}
                                readOnly
                            />
                            <Form.Label>Nome LIB</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.nome}
                                onChange={(event) => {
                                    setLibName(event.target.value)
                                }}
                            />
                            <Form.Label>Edificio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.edificio}
                                onChange={(event) => {
                                    setLibEdificio(event.target.value)
                                }}
                            />
                            <Form.Label>Subnet</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.subnet}
                                onChange={(event) => {
                                    setLibSubnet(event.target.value)
                                }}
                            />
                            <Form.Label>IP Pubblico LIB</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.ip}
                                onChange={(event) => {
                                    setLibIp(event.target.value)
                                }}
                            />


                            <br />

                        </Form.Group>
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalLib}>
                        Annulla
                    </Button>
                    <Button variant="contained" onClick={handleSaveModalLib}>
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        return retModalLib;

    }

    const modalPc = () => {
        if (!showModalPc) return;

        var retModalPc

        retModalPc = (
            <Modal
                className={'modal-dialog-centered'}
                show={showModalPc}
                onHide={handleCloseModalPc}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifica Postazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group controlId="ModalForm.ScheduleEvent1">
                            <Form.Label>ID LIB</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.idLabInventory}
                                readOnly="true"
                            />
                            <Form.Label>Nome LIB</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.nome}
                                readOnly="true"
                            />
                            <Form.Label>Edificio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.edificio}
                                readOnly="true"
                            />
                            <Form.Label>Subnet</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.subnet}
                                readOnly="true"
                            />
                            <Form.Label>IP pubblico LIB</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={libPayload.ip}
                                readOnly="true"
                            />

                            <Form.Label>Computer Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={pcComputerName}
                                readOnly="true"
                            />
                            <Form.Label>Mac Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={pcMacAddress}
                                onChange={(event) => {
                                    setPcMacAddress(event.target.value)
                                }}
                            />
                            <Form.Label>IP Postazione</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={pcIp}
                                onChange={(event) => {
                                    setPcIp(event.target.value)
                                }}
                            />


                            <br />

                        </Form.Group>
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalPc}>
                        Annulla
                    </Button>
                    <Button variant="contained" onClick={handleSaveModalPc}>
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        return retModalPc;

    }
      

    const expandRow = {
        onExpand: (row, isExpand, rowIndex, e) => {
            setLibIdLabInventory(row.idLabInventory);
            setLibPostazioni(row.postazioni);
            setLibName(row.nome);
            setLibEdificio(row.edificio);
            setLibSubnet(row.subnet);
            setLibIp(row.ip);
        },
        renderer: row => (
            <div>
                <p>{`Postazioni del laboratorio ${row.nome}`}</p>
                <Table striped hover condensed bordered={false} size="sm">
                    <thead>
                        <tr>
                            {columnsPc.map((column) => (
                                <th key={column.dataField}>{column.text}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {row.postazioni.map((postazione) => (
                            <tr key={postazione.computerName}>
                                {columnsPc.map((column) => (
                                    <td key={column.dataField} >
                                        {String(postazione[column.dataField])}
                                    </td>
                                ))}
                                <td>
                                    <span onClick={(e) => {
                                        e.stopPropagation(); // Previene l'espansione della riga quando si fa clic sull'icona Pen

                                        var payload = {
                                            idLabInventory: row.idLabInventory,
                                            nome: row.nome,
                                            edificio: row.edificio,
                                            subnet: row.subnet,
                                            ip: row.ip,
                                            postazioni: row.postazioni,
                                        };

                                        console.log("debug: " + JSON.stringify(payload) )
                                        setPcMacAddress(postazione.macAddress);
                                        setPcIp(postazione.ip);
                                        setPcComputerName(postazione.computerName);
                                        setLibPayload(payload);

                                        setShowModalPc(true);
                                    }}>
                                        <Pen />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        ),
        showExpandColumn: true,
        onlyOneExpanding: true,
    };


    const columnsLib = [
        {
            dataField: "idLabInventory",
            text: "ID LIB",
            sort: true
        },
        {
            dataField: "nome",
            text: "Nome LIB",
            sort: true
        },
        {
            dataField: "edificio",
            text: "Edificio",
            sort: true
        },
        {
            dataField: "subnet",
            text: "Subnet",
            sort: true
        },
        {
            dataField: "ip",
            text: "IP",
            sort: true
        },
        {
            dataField: "abilitato",
            text: "Abilitato",
            sort: true
        }
    ];

    const columnsPc = [
        {
            dataField: "computerName",
            text: "Computer Name",
            sort: true
        },
        {
            dataField: "macAddress",
            text: "MAC Address",
            sort: true
        },
        {
            dataField: "ip",
            text: "IP",
            sort: true
        }
    ];


    if (!dataLib) {
        ret = null;
    } else {
        ret = (
            <React.Fragment>
                {modalLib()}
                {modalPc()}

                <h2 className="title-page">Inventario LIB (Laboratori fisici) </h2>

                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="searchLib" style={{ width: "85%" }}>
                        <Form.Label>Ricerca LIB</Form.Label>
                        <Row>
                            <Col>
                                <Form.Text className="text-muted">Selezionare la chiave</Form.Text>
                            </Col>
                            <Col>
                                <Form.Text className="text-muted">Inserire il Valore</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DropdownButton id="dropdown-basic-button" title={chiaveDes}>
                                    <Dropdown.Item onClick={selectChiaveNome} >Nome LIB</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveEdificio}>Edificio</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveSubnet}>Subnet</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveIp}>IP</Dropdown.Item>
                                </DropdownButton>
                            </Col>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchLibHandler}
                                    //placeholder={chiave}
                                    value={searchLib}
                                    readOnly={!isKeySelected}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <ClipLoader
                                    size={55}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <Table striped hover condensed bordered={false} size="sm">
                    <thead>
                        <tr>
                            {columnsLib.map((column) => (
                                <th key={column.dataField}>{column.text}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataLib.map((row) => (
                            <React.Fragment key={row.idLabInventory}>
                                <tr>
                                    {columnsLib.map((column) => (
                                        <td key={column.dataField} onClick={() => setExpandedRow(row.idLabInventory === expandedRow ? null : row.idLabInventory)}>
                                            {String(row[column.dataField])}
                                        </td>
                                    ))}
                                    <td>
                                        <span onClick={(e) => {
                                            e.stopPropagation(); // Previene l'espansione della riga quando si fa clic sull'icona Pen

                                            var payload = {
                                                idLabInventory: row.idLabInventory,
                                                nome: row.nome,
                                                edificio: row.edificio,
                                                subnet: row.subnet,
                                                ip: row.ip,
                                                postazioni: row.postazioni,
                                            };

                                            setLibIdLabInventory(row.idLabInventory);
                                            setLibName(row.nome);
                                            setLibEdificio(row.edificio);
                                            setLibSubnet(row.subnet);
                                            setLibIp(row.ip);
                                            setLibPostazioni(row.postazioni);

                                            setLibPayload(payload);

                                            setShowModalLib(true);
                                        }}>
                                            <Pen />
                                        </span>
                                    </td>
                                </tr>
                                {row.idLabInventory === expandedRow && (
                                    <tr>
                                        <td colSpan={columnsLib.length + 1}>
                                            {expandRow.renderer(row)}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>

            </React.Fragment>
        );
    }



    return ret;

}

export default GestioneLib